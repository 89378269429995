import Analytics from '../services/AnalyticsService'

const Background = [
  {
    id: 'bg',
    message: 'Ennen tulosten esittämistä kysyisin kolme taustakysymykstä yrityksestäsi.',
    trigger: 'bgq1'
  },
  {
    id: 'bgq1',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Koko', message: 'Yrityksen koko:' }),
    trigger: 'bga1'
  },
  {
    id: 'bga1',
    options: [
      { value: 1, label: 'Pieni', trigger: 'bgq2'},
      { value: 2, label: 'Keskisuuri', trigger: 'bgq2'},
      { value: 3, label: 'Suuri ', trigger: 'bgq2'}
    ]
  },
  {
    id: 'bgq2',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Asema', message: 'Vastaajan asema:' }),
    trigger: 'bga2'
  },
  {
    id: 'bga2',
    options: [
      { value: 1, label: 'Ylin johto', trigger: 'bgq3'},
      { value: 2, label: 'Keskijohto', trigger: 'bgq3'},
      { value: 3, label: 'Toimihenkilö', trigger: 'bgq3'}
    ]
  },
  {
    id: 'bgq3',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Työtehtävä', message: 'Työtehtävä suhteessa vastuullisuuteen:' }),
    trigger: 'bga3'
  },
  {
    id: 'bga3',
    options: [
      { value: 1, label: 'Vastuullisuudesta kokonaisuutena vastaava henkilö', trigger: 'calculator'},
      { value: 2, label: 'Vastuullisuudesta osittain vastaava henkilö', trigger: 'calculator'},
      { value: 3, label: 'Vastuullisuus ei kuulu keskeisimpiin työtehtäviini', trigger: 'calculator'}
    ]
  }
]

export default Background;
