import React, { Component } from 'react'
import { connect } from "redux-zero/react";
import ReactGA from 'react-ga';


import actions from "../store/actions";
import { BigImage, ArchetypeContainer, CardImage, Link, Header, Subtitle, Paragraph, Container, DetailContainer, } from './styled'
import Analytics from '../services/AnalyticsService'

import nelikentta from '../img/nelikentta.png';
import vaikuttavuuden_kohdentaja from '../img/vaikuttavuuden_kohdentaja.png';
import visionaarinen_vastuunkantaja from '../img/visionaarinen_vastuunkantaja.png';
import vaatimuksiin_reagoija from '../img/vaatimuksiin_reagoija.png';
import vastuullinen_raportoija from '../img/vastuullinen_raportoija.png';



class Calculator extends Component {
  constructor(props) {
    super(props)
    this.archeRef = React.createRef()
    this.state = {}
  }

  componentWillMount() {
    ReactGA.pageview('testCalculator');

    const { steps } = this.props

    let points = {
      proactive: 0,
      focused: 0
    }

    let bga = {
      bga1: 'Size',
      bga2: 'Position',
      bga3: 'Responsibility'
    }

    Object.keys(steps).map((key) => {
      if (steps[key]['id'].includes('answer')) {
        Analytics.message({ category: 'TestAnswers', action: steps[key]['id'], label: steps[key]['message'] })
        points.proactive += steps[key]['metadata']['points'][steps[key]['message']]['proactive']
        points.focused += steps[key]['metadata']['points'][steps[key]['message']]['focused']
      } else if (steps[key]['id'].includes('bga')) {
        Analytics.message({ category: 'TestBackgrounds', action: steps[key]['id'], label: steps[key]['message'] })
        bga[steps[key]['id']] = {
          id: steps[key]['id'],
          message: steps[key]['message']
        }
      }

      return points
    })

    points = points.proactive === 0 && points.focused === 0 ? {proactive: 14, focused: 1} : points
    points = {proactive: points.proactive / 17 * 8, focused: points.focused / 7 * 8}

    const params = { points };
    this.setState(params);
  }

  componentDidMount() {
    this.archeRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  calculateArchetypes() {
    const { points } = this.state;

    const headers = {
      vaikuttavuuden_kohdentaja: {
        file: vaikuttavuuden_kohdentaja,
        title: 'vaikuttavuuden kohdentaja',
        description: `Vaikuttavuuden kohdentajaon kohdennetun fokuksen proaktiivinen toimija. Johto peilaa yrityksen toimintaa yhteiskunnallisiin tarpeisiin ja valitsee niiden pohjalta ne vastuullisuuden alueet, joissa yritys haluaa tehdä erinomaista jälkeä. Koska fokus on tiukka, yrityksellä on mahdollisuus oppia ja kehittyä rajatulla osa-alueella myös yhteiskunnallisen vaikuttavuuden huippuosaajaksi. Vaikuttavuuden kohdentaja voi olla esimerkiksi pieni- tai keskisuuri kasvuyritys, joka on kiteyttänyt yrityksen yhteiskunnallisen tarkoituksen tai olemassa olon yhteen ydinajatukseen, joka ohjaa kaikkia tekoja.`,
        secondary: ['visionaarinen_vastuunkantaja', 'vaatimuksiin_reagoija']
      },
      visionaarinen_vastuunkantaja: {
        file: visionaarinen_vastuunkantaja,
        title: 'visionäärinen vastuunkantaja',
        description: `Visionäärinen vastuunkantaja on laajan fokuksen proaktiivinen toimija. Todennäköisimmin se on suurehko kansainvälinen yritys, jolle vastuullisuus on toiminnan päämäärä. Yritys näkee vastuullisessa toiminnassa mahdollisuuden kestävään menestykseen usealla rintamalla. Kyseessä voi olla monialayritys, jonka eri yksiköt kunnostautuvat vastuullisuuden eri osa-alueilla. Yrityksen eri yksiköt oppivat toisiltaan ja kokeilevat uusia tapoja, joilla vastuullisten tekojen yhteiskunnallista vaikuttavuutta voidaan lisätä. Visionäärinen vastuunkantaja nauttii sidosryhmien keskuudessa arvostusta ja on sekä haluttu että halukas kumppani erilaisiin yhteishankkeisiin.`,
        secondary: ['vaikuttavuuden_kohdentaja', 'vastuullinen_raportoija']
      },
      vaatimuksiin_reagoija: {
        file: vaatimuksiin_reagoija,
        title: 'vaatimuksiin reagoija',
        description: `Vaatimuksiin reagoija on vastuullisuudessaan kapean fokuksen reaktiivinen toimija. Vastuullisuus ei ole keskeisessä roolissa yrityksen liiketoimintastrategiassa, mutta yritys haluaa vastata sidosryhmien odotuksiin ja toimia oikein. Vastuullisuudella voi olla liiketoiminnalle välinearvoa. Vastuu ja yhteiskunnallinen vaikuttavuus voidaan esimerkiksi ’ulkoistaa’ lahjoitusten tai sponsoroinnin kautta järjestöille ja muille yrityksen ulkopuolisille toimijoille. Tyypillinen esimerkki voisi olla paikallinen pk-yrittäjä, joka toteuttaa yhteiskuntavastuuta siten, että tukee paikallista juniorijalkapalloseuraa. Lisäksi yritys keskittyy oman liiketoiminnan kannalta olennaisten ympäristöasioiden hoitamiseen ja niihin liittyvän lainsäädännön huolelliseen noudattamiseen. Paikallinen yrittäjä arvostaa työntekijöitään ja kouluttaa heitä mahdollisimman paljon ja jopa kannustaa vapaaehtoistyöhön. Yhteiskuntavastuusta raportointi sen sijaan rajoittuu lain minimivaatimuksiin.`,
        secondary: ['vastuullinen_raportoija', 'vaikuttavuuden_kohdentaja']
      },
      vastuullinen_raportoija: {
        file: vastuullinen_raportoija,
        title: 'vastuullinen raportoija',
        description: `Laajan fokuksen reaktiivista toimijaa kutsumme Vastuulliseksi raportoijaksi. Vastuullinen raportoija tuntee paikalliset ja kansainväliset normit. Yritys toteuttaa vastuullisuutta varsin laajojen kansainvälisten normien mukaan ja raportoi toiminnastaan niiden mukaisesti. Vastuullisuus täydentää olemassa olevia bisnesmalleja ja vaikutuksista raportoidaan esimerkiksi osana laatujärjestelmiä. Yritykset voivat myös raportoida niin kutsutun kolmoistilinpäätöksen (talous-, ympäristö- ja sosiaaliset vaikutukset) periaatteiden mukaisesti.`,
        secondary: ['vaatimuksiin_reagoija', 'visionaarinen_vastuunkantaja']
      }
    }

    let archetypes = {}

    archetypes.primary = (points.proactive >= 0) ?
      ((points.focused >= 0) ? headers['vaikuttavuuden_kohdentaja'] : headers['visionaarinen_vastuunkantaja']) :
      ((points.focused >= 0) ? headers['vaatimuksiin_reagoija'] : headers['vastuullinen_raportoija']);

    archetypes.secondary = (points.proactive / points.focused) >= 1 || (points.proactive / points.focused) <= -1 ? headers[archetypes['primary']['secondary'][0]] : headers[archetypes['primary']['secondary'][1]]

    archetypes.potency = (Math.hypot(points.proactive, points.focused) >= 4 ? 'Erittäin vahva ' :
      (Math.hypot(points.proactive, points.focused) >= 2) ? 'Vahva ' : 'Kohtalainen ')

    return archetypes
  }

  render() {
    const archetypes = this.calculateArchetypes()
    Analytics.message({ category: 'TestArchetypes', action: 'Primary Archetype', label: archetypes.primary.title })

    return (
      <DetailContainer>
        <div ref={this.archeRef}></div>
        <ArchetypeContainer>
          <Subtitle>Yrityksesi edustaa eniten vastuullisen yrityksen arkkityyppiä</Subtitle>
          <Header>{archetypes.potency}{archetypes.primary.title}</Header>
          <Paragraph>{archetypes.primary.description}</Paragraph>
          <CardImage src={archetypes.primary.file}/>
        </ArchetypeContainer>
        <ArchetypeContainer>
          <Subtitle>Yrityksessäsi esiintyy myös arkkityyppiä</Subtitle>
          <Header>{archetypes.secondary.title.charAt(0).toUpperCase() + archetypes.secondary.title.substring(1)}</Header>
          <Paragraph>{archetypes.secondary.description}</Paragraph>
          <CardImage src={archetypes.secondary.file}/>
        </ArchetypeContainer>
        <Container style={{float: 'left'}}>
          <Header>Vastuullisen yrityksen arkkityypit</Header>
          <Link href="https://ek.fi/vastuullisuus/vastuullisen-yrityksen-nelja-perustoimintamallia/" target="_blank">Tutustu arkkityyppeihin tarkemmin ja lue lisää EK:n sivuilla</Link>
          <BigImage src={nelikentta}/>
        </Container>
      </DetailContainer>
    );
  }
}

export default connect(null, actions)(Calculator);
