import React from 'react'

import Introduction from './introduction'
import Background from './background'
import Questions from './questions'
import Calculator from '../components/calculator'

const Steps = Introduction.concat(Questions).concat(Background).concat(
  {
    id: 'calculator',
    component: <Calculator />,
    waitAction: true,
    trigger: 'question1',
  }
)

export { Steps };
