import React, { Component } from 'react';
import { IntroContainer, IntroHeader, IntroImage, IntroSubtitle, IntroParagraph, IntroLogo, Link } from './styled';
import avatar from '../hank_intro.svg';
import logo from '../ek_logo.png';

class Intro extends Component {
  render() {
    return (
      <IntroContainer>
        <IntroImage src={avatar}/>
        <IntroHeader>Hank EK</IntroHeader>
        <IntroSubtitle>VASTUULLISUUSBOTTI</IntroSubtitle>
        <IntroParagraph>Hei, minä olen Vastuullisuusbotti Hank! Vastaa 21 esittämääni kysymykseen ja selvitä mitä vastuullisuuden arkkityyppiä yrityksesi parhaiten edustaa.</IntroParagraph>
        <Link href="https://ek.fi" target="_blank">
          <IntroLogo src={logo}/>
        </Link>
      </IntroContainer>
    );
  }
}

export default Intro
