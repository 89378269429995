import ReactGA from 'react-ga';

export default {
  message: (packet) => {
    ReactGA.event({
      category: packet.category,
      action: packet.action,
      label: packet.label
    });
    return packet.message;
  }
};
