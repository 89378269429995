import React from 'react'
import Intro from '../components/intro'


const Introduction = [
  {
    id: 'intro',
    component: <Intro />,
    delay: 0,
    trigger: 'question1'
  },
]

export default Introduction;
