import Analytics from '../services/AnalyticsService'

const Questions = [
  {
    id: 'question1',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question1', message: 'Ovatko vastuullisuusasiat keskeisessä osassa kirjoittamaanne liiketoimintastrategiaa?' }),
    trigger: 'answer1'
  },
  {
    id: 'answer1',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question2'},
      { value: 2, label: 'Ei', trigger: 'question2'}
    ]
  },
  {
    id: 'question2',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question2', message: 'Ovatko lahjoitukset ja sponsorointi keskeisimmät tekijät vastuullisuuden toteuttamisessa?' }),
    trigger: 'answer2'
  },
  {
    id: 'answer2',
    metadata: {
      points: {
        'Kyllä': { proactive: -1, focused: 1 },
        'Ei': { proactive: 1, focused: -1 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question3'},
      { value: 2, label: 'Ei', trigger: 'question3'}
    ]
  },
  {
    id: 'question3',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question3', message: 'Kun mietit toteuttamianne konkreettisia vastuullistoimia, ovatko ne kohdistuneet enemmän paikallisiin haasteisiin kuin laajoihin yhteiskunnallisiin kysymyksiin?' }),
    trigger: 'answer3'
  },
  {
    id: 'answer3',
    metadata: {
      points: {
        'Kyllä': { proactive: 0, focused: 1 },
        'Ei': { proactive: 0, focused: -1 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question4'},
      { value: 2, label: 'Ei', trigger: 'question4'}
    ]
  },
  {
    id: 'question4',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question4', message: 'Kun mietit yrityksenne vastuullisuusraportointia, koskeeko se enemmän ydinliiketoimintanne osa-alueita kuin laajoja yhteiskunnallisia kysymyksiä?' }),
    trigger: 'answer4'
  },
  {
    id: 'answer4',
    metadata: {
      points: {
        'Kyllä': { proactive: 0, focused: 1 },
        'Ei': { proactive: 0, focused: -1 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question5'},
      { value: 2, label: 'Ei', trigger: 'question5'}
    ]
  },
  {
    id: 'question5',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question5', message: 'Onko vastuullisuus yrityksenne toiminnan päämäärä?' }),
    trigger: 'answer5'
  },
  {
    id: 'answer5',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question6'},
      { value: 2, label: 'Ei', trigger: 'question6'}
    ]
  },
  {
    id: 'question6',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question6', message: 'Jaetaanko vastuullisuustietoa yrityksenne sisällä asiantuntijoiden kesken?' }),
    trigger: 'answer6'
  },
  {
    id: 'answer6',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question7'},
      { value: 2, label: 'Ei', trigger: 'question7'}
    ]
  },
  {
    id: 'question7',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question7', message: 'Jaetaanko vastuullisuustietoa yrityksenne johtoryhmästä toimihenkilöille ?' }),
    trigger: 'answer7'
  },
  {
    id: 'answer7',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question8'},
      { value: 2, label: 'Ei', trigger: 'question8'}
    ]
  },
  {
    id: 'question8',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question8', message: 'Jaetaanko vastuullisuustietoa yrityksenne hallituksesta johtoryhmälle ?' }),
    trigger: 'answer8'
  },
  {
    id: 'answer8',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question9'},
      { value: 2, label: 'Ei', trigger: 'question9'}
    ]
  },
  {
    id: 'question9',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question9', message: 'Korostuuko vastuullisuusraportoinnissa enemmän yksi näkökulma (kuten talous, ympäristö tai sosiaalinen vastuu) kuin useampi ?' }),
    trigger: 'answer9'
  },
  {
    id: 'answer9',
    metadata: {
      points: {
        'Kyllä': { proactive: 0, focused: 1 },
        'Ei': { proactive: 0, focused: -1 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question10'},
      { value: 2, label: 'Ei', trigger: 'question10'}
    ]
  },
  {
    id: 'question10',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question10', message: 'Käsitelläänkö vastuullisuutta yrityksenne johtoryhmän kokouksissa viikottain?' }),
    trigger: 'answer10'
  },
  {
    id: 'answer10',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question11'},
      { value: 2, label: 'Ei', trigger: 'question11'}
    ]
  },
  {
    id: 'question11',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question11', message: 'Raportoitteko vastuullisuudesta valmiin ohjeistuksen tai standardin pohjalta?' }),
    trigger: 'answer11'
  },
  {
    id: 'answer11',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question12'},
      { value: 2, label: 'Ei', trigger: 'question12'}
    ]
  },
  {
    id: 'question12',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question12', message: 'Löytyykö organisaatioistanne ihminen jonka tehtävänä on vastaa pelkästään vastuullisuuskysymyksistä?' }),
    trigger: 'answer12'
  },
  {
    id: 'answer12',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question13'},
      { value: 2, label: 'Ei', trigger: 'question13'}
    ]
  },
  {
    id: 'question13',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question13', message: 'Etsittekö sosiaalisesti mediasta tai erilaisista julkaisuista vastauksia yhteiskunnallisiin haasteisiin? ' }),
    trigger: 'answer13'
  },
  {
    id: 'answer13',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question14'},
      { value: 2, label: 'Ei', trigger: 'question14'}
    ]
  },
  {
    id: 'question14',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question14', message: 'Meneekö työssäsi enemmän aikaa sidosryhmien odotuksiin vastaamiseen  kuin oma-aloitteisten toimien kehittämiseen ja toteuttamiseen?' }),
    trigger: 'answer14'
  },
  {
    id: 'answer14',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 1 },
        'Ei': { proactive: -1, focused: -1 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question15'},
      { value: 2, label: 'Ei', trigger: 'question15'}
    ]
  },
  {
    id: 'question15',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question15', message: 'Etsitkö aktiivisesti vastauksia asiakkaasi yhteiskunnallisiin haasteisiin?' }),
    trigger: 'answer15'
  },
  {
    id: 'answer15',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 1 },
        'Ei': { proactive: -1, focused: -1 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question16'},
      { value: 2, label: 'Ei', trigger: 'question16'}
    ]
  },
  {
    id: 'question16',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question16', message: 'Kun mietit konkreettisia vaikuttavuustekojanne, oletteko joutuneet tekemään priorisointia erilaisten vastuullisuuskysymysten välillä?' }),
    trigger: 'answer16'
  },
  {
    id: 'answer16',
    metadata: {
      points: {
        'Kyllä': { proactive: 0, focused: 1 },
        'Ei': { proactive: 0, focused: -1 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question17'},
      { value: 2, label: 'Ei', trigger: 'question17'}
    ]
  },
  {
    id: 'question17',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question17', message: 'Koetko että toteuttamasi vastuullisuusteot edistävät enemmän liiketoiminnallisia tavoitteita kuin sidosryhmien odotuksia?' }),
    trigger: 'answer17'
  },
  {
    id: 'answer17',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question18'},
      { value: 2, label: 'Ei', trigger: 'question18'}
    ]
  },
  {
    id: 'question18',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question18', message: 'Teetkö vastuullisuusraportointia enemmän lainsäädännön velvoittamana kuin oman liiketoiminnan kehittämisen näkökulmasta?' }),
    trigger: 'answer18'
  },
  {
    id: 'answer18',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question19'},
      { value: 2, label: 'Ei', trigger: 'question19'}
    ]
  },
  {
    id: 'question19',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question19', message: 'Onko vastuullisuus johtoryhmän kokouksen asialistalla lähes jokaisessa kokouksessa?' }),
    trigger: 'answer19'
  },
  {
    id: 'answer19',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question20'},
      { value: 2, label: 'Ei', trigger: 'question20'}
    ]
  },
  {
    id: 'question20',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question20', message: 'Onko yrityksellänne pitkän aikavälin (vähintään 5 vuoden) vastuullisuussuunnitelmaa?' }),
    trigger: 'answer20'
  },
  {
    id: 'answer20',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'question21'},
      { value: 2, label: 'Ei', trigger: 'question21'}
    ]
  },
  {
    id: 'question21',
    message: () => Analytics.message({ category: 'TestQuestions', action: 'See Question', label: 'Question21', message: 'Onko vastuullisuudella keskeinen merkitys jokaisen investointipäätöksen osalta?' }),
    trigger: 'answer21'
  },
  {
    id: 'answer21',
    metadata: {
      points: {
        'Kyllä': { proactive: 1, focused: 0 },
        'Ei': { proactive: -1, focused: 0 }
      }
    },
    options: [
      { value: 1, label: 'Kyllä', trigger: 'bg'},
      { value: 2, label: 'Ei', trigger: 'bg'}
    ]
  }
]

export default Questions;
