import React, {Component} from 'react';
import ReactGA from 'react-ga';

import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { Provider } from "redux-zero/react";

import store from "./store/store";
import avatar from './hank_icon.svg';
import { Steps } from './steps/steps'

const styling = {
  avatarStyle: {
    height: '36px'
  },
  botAvatar: avatar,
  bubbleOptionStyle: {
    background: '#00B2A9',
    color: '#fbfbfb'
  },
  contentStyle: {
    marginTop: 0,
    paddingTop: 0
  },
  customStyle: {
    background: '#ededed',
    justifyContent: 'left',
    padding: 0,
    margin: 0,
    boxShadow: '0px 0px 0px 0px'
  },
  hideUserAvatar: true,
  theme: {
    background: '#ededed',
    fontFamily: 'Roboto',
    headerBgColor: '#1f2123',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#fbfbfb',
    botFontColor: '#3e3e3e',
    userBubbleColor: '#3e3e3e',
    userFontColor: '#ededed',
  }
}

const botOptions = {
  width: `${window.innerWidth}px`,
  height: `${window.innerHeight}px`,
  placeholder: 'Kirjoita viesti ...',
  headerTitle: ''
}

function initializeReactGA() {
  ReactGA.initialize('UA-143770680-1', {
    debug: false,
  });
  ReactGA.pageview('testQuestions');
}

class Hank extends Component {

  state = {
    steps: null,
    loading: true,
    speech: null
  };

  // Check if preview content exists in URL query param and combine internal & db originated steps
  setSteps = async () => {
    const steps = Steps;
    this.setState({steps, loading: false});
  };

  componentWillMount() {
    localStorage.removeItem('rsc_cache');
    initializeReactGA()

    this.setSteps();
  }

  componentDidMount() {
    this.handleEnd = this.handleEnd.bind(this);
  }

  handleEnd({ renderedSteps, steps, values }) {
    console.log(renderedSteps, steps, values);
    // The renderedSteps only reachable, when the end-flag is set to true in a step.
    // However, this does not allow to continue the conversation.

    // console.log(values);
    // alert(`Chat handleEnd callback! Number: ${values[0]}`);
  }

  render() {
    return (
      this.state.loading ? <React.Fragment/> :
        <Provider store={store}>
          <ThemeProvider theme={styling.theme}>
            <React.Fragment>
              <ChatBot
                speechSynthesis={{enable: false}}
                handleEnd={this.handleEnd}
                cache={true}
                width={botOptions.width}
                height={botOptions.height}
                placeholder={botOptions.placeholder}
                headerTitle={botOptions.headerTitle}
                steps={this.state.steps}
                bubbleOptionStyle={styling.bubbleOptionStyle}
                hideUserAvatar={styling.hideUserAvatar}
                botAvatar={styling.botAvatar}
                avatarStyle={styling.avatarStyle}
                customStyle={styling.customStyle}
                contentStyle={styling.contentStyle}
              />
            </React.Fragment>
          </ThemeProvider>
        </Provider>
    );
  }
}

export default Hank;
